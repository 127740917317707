<template>
    <template v-if="showAds">
        <!-- <ārsta darbavietas reklāma> -->
        <div v-if="bannerLoading" class="animate-pulse-in-out h-[390px] border-2 border-dashed border-secondary mb-7"></div>

        <ad-banner
            :advert="doctorWorkplaceAdvert"
            class="mb-7"
        />
        <!-- </ārsta darbavietas reklāma> -->

        <template v-if="advert">
            <div v-if="bannerLoading" class="animate-pulse-in-out h-[132px] border-2 border-dashed border-secondary mb-7"></div>

            <ad-banner
                v-else
                :advert="advert"
                class="mb-7"
            />
        </template>
    </template>

    <div v-if="recentArticles.length || articlesLoading" class="px-2 lg:px-0.5">
        <div class="pl-11">
            {{ translations.NewArticles }}:
        </div>

        <div class="flex flex-wrap justify-between items-center">

            <template v-if="articlesLoading">
                <div
                    v-for="n in 3"
                    :key="n"
                    class="animate-pulse-in-out flex w-full lg:w-[312px] h-[132px] mb-1 border-2 border-dashed border-secondary"
                ></div>
            </template>

            <template v-else>
                <article-box
                    v-for="(article, index) in recentArticles"
                    :key="index"
                    :article-data="article"
                    class="w-full lg:w-[312px] h-[132px] mb-1"
                />
            </template>

        </div>
    </div>

</template>

<script setup>
    import { ref } from 'vue';
    import Http from '@/js/global/http'
    import AdBanner from '@/components/AdBanner.vue';
    import ArticleBox from '@/components/articles/ArticleBox.vue';

    const advert = window.advert.data
    const showAds = window.advert.show
    const translations = window.recentArticlesData.translations

    const bannerLoading = ref(false)
    const articlesLoading = ref(true)

    const recentArticles = ref([])


    Http.get(window.recentArticlesData.url, {
        success(response) {
            if (response.articles) {
                recentArticles.value = response.articles
            }
        },
        always() {
            articlesLoading.value = false
        }
    })


    const doctorWorkplaceAdvert = {
        LargeBannerUrl: 'https://bildes.eveselibaspunkts.lv/ad-banners/d645214e-55b1-4be2-a89e-3d1a016dea76.png',
        SmallBannerUrl: 'https://bildes.eveselibaspunkts.lv/ad-banners/a5e9691e-d682-4875-b3de-496ffbf2644a.png',
        ImageAlt: 'EVP ārsta darbavietas kalendārs',
        RedirectUrl: 'https://eveselibaspunkts.lv/kalendars',
    }


    if (showAds && advert) {
        bannerLoading.value = true

        preloadBannerImages().then(() => {
            bannerLoading.value = false
        })
    }

    async function preloadBannerImages() {
        const images = [
            advert.LargeBannerUrl,
            advert.SmallBannerUrl,
            doctorWorkplaceAdvert.LargeBannerUrl,
            doctorWorkplaceAdvert.SmallBannerUrl,
        ]

        await Promise.all(images.map(url => new Promise((resolve, reject) => {
            const image = new Image()

            image.onload = resolve
            image.onerror = reject
            image.src = url
        })))
    }

</script>